import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PushpinOutlined,
  PushpinFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { DataTable, Form, PrimaryButton } from "../../components";
import Fields from "./FormFields.json";
import { ResponderCell, TitleCell } from "./Menu";
import {
  commonActions,
  getTaskSourceTypes,
  taskActions,
  getTasks,
  getTasksTotalCount,
  getAuditAssessmentsList,
  getTaskSourcesFromType,
  isMiscLoaded,
  getActivityTypeList,
  getCustomTypeList,
  getIncidentTypeList,
  getContinuityTypeList,
  getComplianceTask,
  getTaskBlueprintTemplates,
  getTaskCreatedBlueprintTemplates,
  isTasksFetched,
} from "../../store";
import { debounce } from "lodash";
import { OvalLoading } from "../../components/Screens";

const TASKS_PER_PAGE = process.env.REACT_APP_TASK_PER_PAGE || 9;

const Columns = [
  {
    title: "Task ID",
    dataIndex: "task_id_pin",
    key: "task_id_custom",
    width: "170px",
    className: "no-wrap",
  },
  {
    title: "Priority",
    dataIndex: "priority",
    key: "priority",
    width: "140px",
    sort: true,
    render: "color-code",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "140px",
    sort: true,
    render: "color-code",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: "53%",
    search: true,
    supportServerSearch: true,
    classNames: "",
    render: (text) => <TitleCell text={text} />,
  },
  {
    title: "Due Date",
    dataIndex: "due_date_str",
    key: "due_date_str",
    width: "150px",
    sort: true,
  },
  {
    title: "Aging",
    dataIndex: "aging",
    key: "aging",
    width: "80px",
    sort: "agingNum",
    classNames: { cell: "txt-right" },
  },
  {
    title: "Department",
    dataIndex: "department",
    key: "department",
    sort: true,
    width: "150px",
  },
  {
    title: "Responder",
    dataIndex: "responder",
    key: "responder",
    width: "175px",
    sort: "main_responder",
    render: ResponderCell,
  },
  // { title: 'Frequency', dataIndex: 'repetition_occurs', key: 'repetition_occurs', width: '120px', },
];
const getPage = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get("page") || "1";
};

export const TaskTracker = () => {
  const page = getPage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const _ref = React.useRef(null);
  const tasks = useSelector(getTasks) || [];
  const types = getTaskSourceTypes();
  const complainceTypes = getComplianceTask();
  const isLoaded = useSelector(isMiscLoaded) || false;
  const taskCount = useSelector(getTasksTotalCount);
  // const audits = useSelector(getAuditAssessmentsList);
  // const activities = useSelector(getActivityTypeList);
  // const customTypes = useSelector(getCustomTypeList);
  // const incidents = useSelector(getIncidentTypeList);
  // const continuityTests = useSelector(getContinuityTypeList);
  // const bpList = useSelector(getTaskBlueprintTemplates.bind(null, true));
  const bpCreatedList = useSelector(getTaskCreatedBlueprintTemplates);
  const taskListFetched = useSelector(isTasksFetched);
  const typeFilter = useSelector(
    (state) => state.task.typeFilter || { type: "", search: "" }
  );
  const sources = useSelector(
    getTaskSourcesFromType.bind(
      null,
      true,
      typeFilter?.type && typeFilter?.type?.id
    )
  );
  const [currentPage, setCurrentPage] = React.useState(getPage());

  const _filter = React.useRef({
    pageSize: TASKS_PER_PAGE,
    searchText: "",
    search: {},
    query: {},
    pageNo: currentPage,
    removeCount: true,
    enableRecent: true,
  });
  React.useEffect(() => {
    dispatch(commonActions.fetchDepartments());
    dispatch(commonActions.fetchTaskPriorities());
    dispatch(commonActions.fetchTaskStatus());
    dispatch(taskActions.fetchRepeatitionList());
    dispatch(taskActions.fetchDashboard());
    dispatch(taskActions.fetchAuditAssessmentsList());
    dispatch(taskActions.fetchActivityTypeList());
    dispatch(taskActions.fetchCustomTypeList());
    dispatch(taskActions.fetchIncidentTypeList());
    dispatch(taskActions.fetchContinuityTypeList());
    dispatch(taskActions.fetchTaskBlueprintTemplates());
    dispatch(taskActions.fetchTaskCreatedBlueprintTemplates());
  }, []);

  React.useEffect(() => {
    if (isLoaded) {
      loadTasksFromQuery(); // Trigger once when data is loaded
    }
  }, [isLoaded]);
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filterParam = queryParams.get("filter");
    const pageNo = queryParams.get("page") || "1";

    if (filterParam) {
      try {
        const filter = JSON.parse(decodeURIComponent(filterParam));
        // Update the typeFilter and current state
        dispatch(taskActions.setTypeFilter(filter));
        setCurrentPage(pageNo);

        _filter.current = {
          ..._filter.current,
          type: filter.type || "",
          search: filter.search || "",
          pageNo,
        };

        // Fetch the tasks once URL is updated
        loadTasks();
      } catch (error) {
        console.error("Error parsing filter in URL:", error);
      }
    } else {
      setCurrentPage(pageNo);
      loadTasks();
    }
  }, [location.search, dispatch]);

  const loadTasksFromQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const filterParam = queryParams.get("filter");
    const pageNo = queryParams.get("page") || "1";

    if (filterParam) {
      try {
        const filter = JSON.parse(filterParam);

        _filter.current = {
          ..._filter.current,
          type: filter.type || "",
          search: filter.search || "",
          pageNo: pageNo,
        };

        dispatch(taskActions.setTypeFilter(filter));
        setCurrentPage(pageNo);
        loadTasks();
      } catch (error) {
        console.error("Error parsing filter from URL:", error);
      }
    } else {
      // No filter present in URL, reset to initial state if necessary
      _filter.current.pageNo = pageNo;
      setCurrentPage(pageNo);
      loadTasks();
    }
  };

  const debouncedLoadTasks = debounce((filter) => {
    dispatch(taskActions.fetchAllTasks(filter));
  }, 500);

  const loadTasks = (payloadOverride = {}) => {
    let { pageNo, pageSize, removeCount, type, search, searchText } =
      _filter.current;
    // If filters are present and pageNo is "recent", override it to "1"
    const hasFilters =
      type || (search && Object.keys(search).length > 0) || searchText;
    if (pageNo === "recent" && hasFilters) {
      pageNo = "1";
    }
    const payload = {
      pageNo,
      pageSize,
      removeCount,
      ...payloadOverride,
    };

    if (type) {
      if (type.id === "blueprint") {
        payload.type = "compliance";
      } else {
        payload.type = type.id;
      }
      // Check if search is available and valid
      if (search && Object.keys(search).length > 0) {
        payload.search = { [type.fetchKey]: search.id }; // Ensure fetchKey is correct
      }
    }
    // Handle the case where `search` is a string (free-text search)
    else if (typeof search === "string" && search) {
      payload.searchText = search;
    } else if (searchText) {
      payload.searchText = searchText;
    }
    debouncedLoadTasks(payload);
  };

  const getFilterFields = () => {
    const fields = Fields.Filter.map((_) => ({ ..._ }));
    if (typeFilter && typeFilter.type) {
      fields[1].type = "select";
      fields[1].label = "Source";
    }
    return fields;
  };
  const getOptions = (attribute) => {
    let options = [];
    if (attribute === "type") {
      options = [...types, ...complainceTypes];
    }
    if (attribute === "search") {
      if (typeFilter?.type && typeFilter?.type?.id === "blueprint") {
        options = bpCreatedList;
      } else {
        options = sources;
      }
    }
    return options;
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    let updatedFilter = { ...typeFilter };
    if (name === "type") {
      updatedFilter.type = value;
      updatedFilter.search = ""; // Reset search when type changes
    } else if (name === "search") {
      updatedFilter.search = value;
    }

    dispatch(taskActions.setTypeFilter(updatedFilter));
    // Update URL immediately to reflect the new filter
    _filter.current = {
      ..._filter.current,
      type: updatedFilter.type,
      search: updatedFilter.search,
    };

    if (!updatedFilter.type && !updatedFilter.search) {
      const searchParams = new URLSearchParams();
      searchParams.set("page", _filter.current.pageNo || "1");
      _filter.current = {
        ..._filter.current,
        search: {},
        searchText: "",
        enableRecent: true,
      };
      navigate(`${window.location.pathname}?${searchParams.toString()}`);

      // loadTasks();
    }
  };

  const handleSearchFilter = () => {
    const taskFilter = { ...typeFilter };
    // Always reset pageNo to 1 on new search or filter
    _filter.current.pageNo = "1";
    setCurrentPage("1");
    const pageNo = _filter.current.pageNo;
    const hasFilters = taskFilter.type || taskFilter.search;
    const filterParams = {};

    if (taskFilter.type && taskFilter.search) {
      filterParams.type = taskFilter.type;
      filterParams.search = taskFilter.search;
    } else if (taskFilter.type) {
      filterParams.type = taskFilter.type;
    } else if (taskFilter.search) {
      filterParams.search = taskFilter.search;
    }

    // Update the URL
    const searchParams = new URLSearchParams();
    searchParams.set("page", pageNo);
    // Only set the filter in the URL if filters exist
    if (hasFilters) {
      searchParams.set(
        "filter",
        encodeURIComponent(JSON.stringify(filterParams))
      );
    }
    // Navigate to the new URL
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
  };

  const handlePageChange = (pageNo) => {
    _filter.current.pageNo = pageNo;
    setCurrentPage(pageNo);
    // Update the URL with the correct page number
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("page", pageNo);
    navigate(`${window.location.pathname}?${searchParams.toString()}`);
    loadTasks();
  };

  const handleSearch = (colDataIndex, searchText) => {
    _filter.current.searchText = searchText;
    _filter.current.pageNo = "1"; // Reset to first page when searching
    loadTasks();
  };
  const handlePinTask = (taskId, pinStatus) => {
    const updatedVal = { pin_task: pinStatus };
    dispatch(
      taskActions.updateTask({
        taskId,
        updatedVal,
        popup: {
          message: pinStatus ? "Task Pinned Successfully" : "Task Unpinned",
        },
        callback: () => {
          // setCurrentPage(1); // Reset to page 1 in component state
          navigate(`${window.location.pathname}?page=1`); // Navigate to page=1 in URL
        },
      })
    );
  };
  const modifyTasks = tasks.map((task) => {
    const temp = { ...task };
    const Icon = temp["pin_task"] ? PushpinFilled : PushpinOutlined;
    temp["task_id_pin"] = (
      <div className="row w-100">
        <Link
          to={{
            pathname: temp.guid,
            state: { from: location.pathname + location.search },
          }}
          className="c1133CC"
        >
          {temp["task_id"]}
        </Link>
        {_filter.current.pageNo !== "recent" && (
          <Icon
            style={{ color: "#1133CC", marginLeft: 12 }}
            onClick={() =>
              handlePinTask(temp["id"], !Boolean(temp["pin_task"]))
            }
          />
        )}
      </div>
    );
    return temp;
  });
  const handleFilterStatus = () => {
    return Boolean(
      typeFilter?.type || typeFilter?.search || _filter.current.searchText
    );
  };
  return (
    <div className="col w-100 h-100 o-hide tracker" ref={_ref}>
      <div className="row tracker-filter ">
        <Form
          className="row "
          Fields={getFilterFields()}
          getOptions={getOptions}
          formData={typeFilter}
          onChange={handleFilterChange}
        />
        <PrimaryButton
          className="search-btn"
          onClick={handleSearchFilter}
          icon={<SearchOutlined style={{ fontSize: 14, color: "#FFF" }} />}
        />
      </div>
      {!taskListFetched ? (
        <div
          className="col w-100 h-100"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <OvalLoading isSmall />
        </div>
      ) : (
        <DataTable
          noSelection
          emptyMessage="There are no Tasks right now!"
          Columns={Columns}
          rows={modifyTasks}
          totalCount={taskCount}
          onSearch={handleSearch}
          onPageChange={handlePageChange}
          currentPage={currentPage}
          paginationConfig={{
            enableRecent: _filter.current.enableRecent,
            defaultPage: _filter.current.defaultPage,
            pageSize: _filter.current.pageSize,
          }}
          hasFiltersActive={handleFilterStatus()}
        />
      )}
    </div>
  );
};

import React from "react";
import {
  CloseCircleOutlined,
  EditFilled,
  FileTextOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  OvalLoading,
  LinkButton,
  useNavigateBack,
  Form,
  PrimaryButton,
} from "../../components";
import {
  AppActions,
  commonActions,
  taskActions,
  getCurrentTask,
  userActions,
  getTaskProrityTypes,
  isMiscLoaded,
  getTaskStatusTypes,
  isTaskClosedState,
  isTaskOpenState,
  isTaskInvalidState,
} from "../../store";
// import { Button } from 'neumetric-components';
import { TaskHistory } from "./History";
import { TaskMenu } from "./Menu";
import { TaskStatusConfirm } from "./Dialogs";
import Fields from "./FormFields.json";

export const TaskViewScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { taskId } = useParams();
  const miscLoaded = useSelector(isMiscLoaded);
  const task = useSelector(getCurrentTask);
  const priorities = useSelector(getTaskProrityTypes);
  const statusList = useSelector(getTaskStatusTypes);
  const goBack = useNavigateBack();
  const [state, setState] = React.useState({
    show: false,
    editMode: false,
    updated: {},
  });
  React.useEffect(() => {
    if (!miscLoaded) {
      dispatch(commonActions.fetchDepartments());
      dispatch(commonActions.fetchTaskPriorities());
      dispatch(commonActions.fetchTaskStatus());
    }
  }, []);
  React.useEffect(() => {
    if (miscLoaded) {
      dispatch(taskActions.fetchTaskByGuid({ guid: taskId }));
    }
  }, [miscLoaded]);
  React.useEffect(() => {
    if (task) {
      if (task === "NOT_FOUND") {
        return navigate("/page-not-found");
      }
      if (task === "ACCESS_RESTRICTED") {
        return navigate("/access-restricted");
      }
      if (!state.show) {
        dispatch(userActions.fetchActiveUsers());
        updateState({ show: true });
        return;
      }
      if (state.shouldSave && Object.values(state.updated).length) {
        updateState({
          shouldSave: false,
          updated: {},
          priorityNote: "",
          statusNote: "",
        });
      }
    }
  }, [task]);
  const updateState = (value) => {
    setState((_) => ({ ..._, ...value }));
  };
  const handleGoBack = (e) => {
    e.preventDefault();
    goBack();
  };
  const getFormFields = (type) => {
    let fields = [];
    switch (type) {
      case "header":
        fields = Fields.Header.map((_) => {
          let field = { ..._ };
          field.readOnly = !state.editMode;
          if (field.attribute === "status" || field.attribute === "priority") {
            field.className = "f-rest-1";
          } else {
            field.className = "f-rest-2";
            // field.label = task.task_id;
            field.labelClassName = "f20 exo2 bold-700 c238787";
            field.font = "f20 exo2 bold-700";
            field.readOnly = true;
          }
          return field;
        });
        break;
      case "details":
        fields = Fields.Details.map((_) => {
          return {
            ..._,
            rows: 4,
            font: "f16",
            labelClassName: "f16 exo2 bold-600",
            readOnly: !state.editMode,
            required: state.editMode ? _.required : false,
          };
        });
        break;
    }
    return fields;
  };
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "status") {
      updateState({ statusToChange: value });
    } else if (name === "priority") {
      updateState({ priorityToChange: value });
    } else {
      const updated = { ...state.updated };
      updated[name] = value;
      updateState({ updated });
    }
  };
  const getFormData = () => {
    let formData = {};
    [...Fields.Header, ...Fields.Details].forEach(({ attribute }) => {
      // let value;
      // if (state.updated[attribute]) value = state.updated[attribute];
      // else if(!value && task[attribute]) value = task[attribute];
      // formData[attribute] = value || '';
      //removed the above logic because it was not updating formData when the user select the whole text and delete it
      formData[attribute] =
        state.updated[attribute] !== undefined
          ? state.updated[attribute]
          : task[attribute] || "";
    });
    return formData;
  };
  const getOptions = (attribute) => {
    let options = [];
    switch (attribute) {
      case "priority":
        options = priorities;
        break;
      case "status":
        options = statusList;
        break;

      default:
        options = [];
        break;
    }
    return options;
  };
  const handleSaveEdit = () => {
    let shouldSave = false;
    const formData = {},
      missingFields = [],
      updatedFields = {};
    const getUpdatedComment = () => {
      let comment = "",
        updatedFieldsText;
      if (Boolean(state.priorityNote)) {
        updatedFields["Priority"] = false;
        comment += `${state.priorityNote}<br/>`;
      }
      if (Boolean(state.statusNote)) {
        updatedFields["Status"] = false;
        comment += `${state.statusNote}<br/>`;
      }
      updatedFieldsText = Object.keys(updatedFields);
      updatedFieldsText = updatedFieldsText.filter((_) => updatedFields[_]);
      if (updatedFieldsText.length > 0) {
        comment += `<span class='f16'>${updatedFieldsText.join(
          ", "
        )} updated.</span>`;
      }
      return comment;
    };
    if (Object.values(state.updated).length) {
      [...Fields.Header, ...Fields.Details].forEach(
        ({ label, attribute, type, required }) => {
          // Check if the field is updated or if it's already present in the task data
          let value =
            state.updated[attribute] !== undefined
              ? state.updated[attribute]
              : task[attribute];
          // let value = state.updated[attribute];
          if (type === "select" && value) {
            value = value.id;
          }
          if (required && !value) {
            missingFields.push(label);
          }
          if (state.updated[attribute]) {
            updatedFields[label] = true;
            formData[attribute] = value;
          }
        }
      );
      if (missingFields.length > 0) {
        let message = missingFields.join(",");
        message += ` ${missingFields.length > 1 ? "are" : "is"} required`;
        dispatch(AppActions.setAlert({ success: false, message }));
        return;
      }
      // Automatically update the status to "Started" if it is "Pending" and not explicitly changed
      if (task.status?.id === "Pending" && !formData.status) {
        formData.status = "Started";
      }
      if (Object.values(formData).length) {
        shouldSave = true;
        let comment = getUpdatedComment();
        dispatch(
          taskActions.updateTask({
            taskId: task.id,
            updatedVal: formData,
            comment,
          })
        );
      }
    }
    updateState({ editMode: !state.editMode, shouldSave });
  };
  const getHistoryNoteForStatusPriority = (attribute, newValue, note) => {
    return `
      <span class="f16">
        <span class="caps">${attribute}</span> 
          changed from <span class="bold task-${attribute}-text ${String(
      task[attribute] ? task[attribute].id : ""
    ).toLowerCase()}">${task[attribute] ? task[attribute].label : ""}</span> to 
          <span class="bold task-${attribute}-text ${String(
      newValue.id
    ).toLowerCase()}">${newValue.label}</span>. 
          <br/> Note: ${note}
      </span>
    `;
  };
  const handleConfirmNote = (note) => {
    const updated = { ...state.updated };
    if (state.priorityToChange) {
      updated.priority = state.priorityToChange;
      note = getHistoryNoteForStatusPriority(
        "priority",
        state.priorityToChange,
        note
      );
      updateState({ updated, priorityToChange: null, priorityNote: note });
    } else if (state.statusToChange) {
      updated.status = state.statusToChange;
      note = getHistoryNoteForStatusPriority(
        "status",
        state.statusToChange,
        note
      );
      if (
        isTaskClosedState(task.status) &&
        (isTaskOpenState(state.statusToChange) ||
          isTaskInvalidState(state.statusToChange))
      ) {
        note += `<br/><span class="f16">Eval: Closure Date erased.</span>`;
      }
      updateState({ updated, statusToChange: null, statusNote: note });
    }
  };
  const handleCancel = () => {
    // Reset the updated state to discard changes
    setState((prevState) => ({
      ...prevState,
      editMode: false,
      updated: {}, // Clears any staged changes
    }));
  };
  return (
    <React.Fragment>
      {!state.show && <OvalLoading />}
      {Boolean(state.show && task) && (
        <div className="col w-100 h-100 o-hide bgFFF view-task">
          <div className="col w-100 header">
            <LinkButton
              variant="lite"
              className="f14 reg btn-back c1A32C4"
              link="/"
              label="Tracker /"
              onClick={handleGoBack}
            />
            <div className="row w-100 h-btn">
              <Form
                showColon
                className="row details-page-header"
                formData={getFormData()}
                getOptions={getOptions}
                onChange={handleFieldChange}
                Fields={getFormFields("header")}
              />
              <div className="row fields">
                <TaskMenu />
                {state.editMode && (
                  <PrimaryButton
                    label="Cancel"
                    className="gap variant-lite"
                    labelClassName="exo2 f14 cFFF"
                    onClick={handleCancel}
                    color="#F00"
                    icon={<CloseCircleOutlined style={{ color: "#F00" }} />}
                  />
                )}
                <PrimaryButton
                  label={state.editMode ? "Save" : "Edit"}
                  className="gap"
                  labelClassName="exo2 f14 cFFF"
                  onClick={handleSaveEdit}
                  icon={
                    state.editMode ? (
                      <FileTextOutlined style={{ color: "#FFF" }} />
                    ) : (
                      <EditFilled style={{ color: "#FFF" }} />
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="col w-100 f-rest o-hide">
            <div className="row w-100 h-100 o-hide screen-pad">
              <TaskHistory />
              <div className="row f-rest h-100 oy-auto v-start details-sec">
                <Form
                  showColon
                  className="col details-form"
                  formData={getFormData()}
                  onChange={handleFieldChange}
                  Fields={getFormFields("details")}
                />
              </div>
            </div>
          </div>
          <TaskStatusConfirm
            attribute="status"
            open={Boolean(state.statusToChange)}
            newValue={state.statusToChange}
            onClose={updateState.bind(null, { statusToChange: null })}
            onConfirm={handleConfirmNote}
          />
          <TaskStatusConfirm
            attribute="priority"
            open={Boolean(state.priorityToChange)}
            newValue={state.priorityToChange}
            onConfirm={handleConfirmNote}
            onClose={handleConfirmNote.bind(null, { priorityToChange: null })}
          />
        </div>
      )}
    </React.Fragment>
  );
};

import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, Pagination } from "antd";
import { Button } from "../Button";
import { OvalLoading } from "../Screens";
import { SearchComponent } from "./Search";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "./style.scss";

const PaginationDefaultConfig = {
  enableRecent: false,
  pageSize: 9,
  defaultPage: 1,
};

const EmptyData = (props) => {
  const { height, message } = props;
  return (
    <div className="col h-ctr v-ctr" style={{ height }}>
      <h6 className="exo2 f16 med c00085">{message || "No Data"}</h6>
    </div>
  );
};
const HeaderCell = (props) => {
  const { className, title } = props;
  return (
    <span
      className={`exo2 f16 c0085 bold-500 ${className || ""}`}
      dangerouslySetInnerHTML={{ __html: title }}
    ></span>
  );
};
const CustomHighlighter = (props) => {
  const { searchText, text, ...rest } = props;
  return (
    <Highlighter
      highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ""}
      {...rest}
    />
  );
};
const DocumentTitle = (col, navigate, text, record, index) => {
  const { unlink, link, guid, id, linkTarget } = record;
  const targetLink = `${link || guid || id}`;
  const className = `${col.cellClassName || "f14"} line-22 c0133CC`;
  const handleClick = (e) => {
    e.preventDefault();
    if (linkTarget && linkTarget === "_blank") {
      window.open(e.target.href, "_blank");
    } else {
      navigate(targetLink);
    }
  };
  return unlink ? (
    Cell(col, text, record, index)
  ) : (
    <Link
      to={targetLink}
      target={linkTarget}
      className={className}
      onClick={handleClick}
    >
      {col.searchedColumn === col.dataIndex ? (
        <CustomHighlighter searchText={col.searchText} text={text} />
      ) : (
        text
      )}
    </Link>
  );
};
const HTMLCell = (col, text, record) => {
  const content =
    text && typeof text === "object" && text.label ? text.label : text;
  return col.searchedColumn === col.dataIndex ? (
    <CustomHighlighter searchText={col.searchText} text={content} />
  ) : (
    <p
      className="ck-container"
      dangerouslySetInnerHTML={{ __html: content }}
    ></p>
  );
};
const Cell = (col, text, record, index) => {
  const content =
    text && typeof text === "object" && text.label ? text.label : text;
  return (
    <div
      className={`f16 c0085 cell line-22 bold-400 ${col.cellClassName || ""} `}
    >
      {col.searchedColumn === col.dataIndex ? (
        <CustomHighlighter searchText={col.searchText} text={content} />
      ) : (
        content
      )}
    </div>
  );
};
const ButtonCell = (col, text, record) => {
  const { icon, onClick, cellClassName } = col;
  return (
    <div className="col w-100">
      <Button
        className={cellClassName}
        label={text}
        icon={icon}
        onClick={(e) => onClick && onClick(e, record)}
      />
    </div>
  );
};
const ColorCodeCell = (col, text) => {
  const label =
    text && typeof text === "object" && text.label ? text.label : text;
  let id = text && typeof text === "object" && text.id ? text.id : text;
  id = String(id || "").toLowerCase();
  const cellName = `${col.dataIndex}-cell`;
  return (
    <div className={`row cell ${cellName} ${col.cellClassName || ""} ${id}`}>
      <div className={`bullet`}></div>
      <span className={`f16 c000 line-22 caps`}>
        {col.searchedColumn === col.dataIndex ? (
          <CustomHighlighter searchText={col.searchText} text={label} />
        ) : (
          label
        )}
      </span>
    </div>
  );
};
const ColorTextCell = (col, text) => {
  const content =
    text && typeof text === "object" && text.label ? text.label : text;
  return (
    <span
      className={`${col.cellClassName || "f14"} c000 line-22 caps ${
        col.dataIndex
      } ${String(content || "").toLowerCase()}`}
    >
      {col.searchedColumn === col.dataIndex ? (
        <CustomHighlighter searchText={col.searchText} text={content} />
      ) : (
        content
      )}
    </span>
  );
};
const Sorter = (dataIndex, recordA, recordB) => {
  var nameA = recordA[dataIndex],
    nameB = recordB[dataIndex];
  nameA = String(
    (nameA && typeof nameA === "object" ? nameA.label : nameA) || ""
  ).toLowerCase();
  nameB = String(
    (nameB && typeof nameB === "object" ? nameB.label : nameB) || ""
  ).toLowerCase();
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const DataTable = (props) => {
  const {
    rows,
    onSelectionChange,
    EmptyComponent,
    emptyProps,
    Columns,
    noSelection,
    totalCount,
    currentPage,
  } = props;
  const tableClassNames = props.classNames || {};
  const paginationConfig = {
    ...PaginationDefaultConfig,
    ...(props.paginationConfig || {}),
  };
  const [state, setState] = useState({
    searchedColumn: null,
    searchText: "",
    height: null,
    showfilterColumn: "",
  });
  const searchInput = useRef(null);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const container = tableRef.current;
    const updateHeight = () => {
      if (container) {
        updateState({ height: container.clientHeight });
      }
    };
    const resizeObserver = new ResizeObserver(updateHeight);
    if (container) {
      resizeObserver.observe(container);
      updateHeight();
    }
    // Cleanup observer on component unmount
    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, []);
  const updateState = (newValues) => {
    setState((_) => ({ ..._, ...(newValues || {}) }));
  };
  const handleServerSearch = (col, searchText) => {
    if (col.supportServerSearch) {
      props.onSearch && props.onSearch(col.dataIndex, searchText);
    }
  };

  const getPaginationCount = () => {
    if (paginationConfig.enableRecent) {
      return totalCount + paginationConfig.pageSize;
    }
    return totalCount;
  };

  const handlePageChange = (pageNo) => {
    if (pageNo === 1 && props.hasFiltersActive) {
      return;
    }
    // Prevent changing the page if already on 'recent'
    if (pageNo === "recent" && currentPage === "recent") return;
    if (paginationConfig.enableRecent) {
      pageNo = pageNo === 1 ? "recent" : pageNo - 1;
    }
    props.onPageChange && props.onPageChange(pageNo);
  };

  const getFilterOptions = (column) => {
    let filterProps = {
      filterMultiple: false,
      filterDropdownOpen: state.showfilterColumn === column.dataIndex,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <SearchComponent
            {...column}
            value={selectedKeys[0]}
            searchInput={searchInput}
            onSearchChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onFilter={() => {
              confirm();
              handleServerSearch(column, selectedKeys[0]);
              updateState({
                searchText: selectedKeys[0],
                searchedColumn: column.dataIndex,
              });
            }}
            onReset={() => {
              updateState({ searchText: "" });
              clearFilters();
            }}
            onClear={() => {
              handleServerSearch(column, "");
              updateState({ searchText: "", searchedColumn: null });
              clearFilters();
              confirm();
            }}
            onSearch={() => {
              confirm({ closeDropdown: false });
              handleServerSearch(column, selectedKeys[0]);
              updateState({
                searchText: selectedKeys[0],
                searchedColumn: column.dataIndex,
              });
            }}
          />
        );
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={filtered ? { color: "#1890ff" } : {}} />
      ),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          updateState({ showfilterColumn: column.dataIndex });
          setTimeout(
            () => searchInput.current && searchInput.current?.focus(),
            100
          );
        } else {
          updateState({ showfilterColumn: "" });
        }
      },
    };
    if (!column.supportServerSearch) {
      filterProps.onFilter = (value, record) => {
        let attributeVal = record[column.dataIndex];
        attributeVal =
          attributeVal && typeof attributeVal === "object"
            ? attributeVal.label
            : attributeVal || "";
        return attributeVal
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      };
    }
    return filterProps;
  };
  const getCellClassName = (col, key = "cell") => {
    const globalHeaderClass = tableClassNames[key] || "";
    const classNames = col.classNames || {};
    return `${classNames[key] || ""} ${globalHeaderClass}`;
  };
  const getColumns = () => {
    const columns = Columns || [];
    return columns.map((_) => {
      let col = { ..._ };
      col.titleText = col.title;
      col.cellClassName = getCellClassName(col);
      col.title = (
        <HeaderCell
          title={col.titleText}
          className={getCellClassName(col, "header")}
        />
      );
      col.searchedColumn = state.searchedColumn;
      col.searchText = state.searchText;
      if (col.render) {
        if (typeof col.render === "string") {
          switch (col.render) {
            case "color-code":
              col.render = ColorCodeCell.bind(null, col);
              break;
            case "color-text":
              col.render = ColorTextCell.bind(null, col);
              break;
            case "link":
              col.render = DocumentTitle.bind(null, col, navigate);
              break;
            case "btn":
              col.render = ButtonCell.bind(null, col);
              break;
            case "html":
              col.render = HTMLCell.bind(null, col);
              break;
            default:
              col.render = Cell.bind(null, col);
          }
        }
      } else {
        col.render = Cell.bind(null, col);
      }
      if (col.sort) {
        let sortIndex = typeof col.sort === "string" ? col.sort : col.dataIndex;
        col.sorter = Sorter.bind(null, sortIndex);
      }
      if (col.search) {
        col = { ...col, ...getFilterOptions(col) };
      }
      return col;
    });
  };
  const getScrollOffset = () => {
    let offset = state.height;
    offset -= 32 + (totalCount > 0 ? 52 : 0); // delete header and pagination
    return offset;
  };
  const customItemRender = (page, type, originalElement) => {
    if (type === "jump-next" || type === "jump-prev") {
      return originalElement;
    }

    const isFirstItem = page === 1;
    const filtersActive = props.hasFiltersActive;
    const pageNo = Boolean(paginationConfig.enableRecent)
      ? isFirstItem
        ? "Recent"
        : page - 1
      : page;

    return (
      <div className="col page-item v-ctr h-ctr" title={pageNo}>
        {type === "page" && Boolean(paginationConfig.enableRecent) ? (
          <React.Fragment>
            {isFirstItem ? (
              <div
                className={`recent-icon ${
                  filtersActive ? "recent-disabled" : ""
                }`}
                onClick={(e) => {
                  if (filtersActive) {
                    e.stopPropagation();
                    return;
                  }
                  handlePageChange("recent");
                }}
              ></div>
            ) : (
              <span className={`exo2 f14 page-no`}>{pageNo}</span>
            )}
          </React.Fragment>
        ) : (
          originalElement
        )}
      </div>
    );
  };

  return (
    <div className="col w-100 h-100 table-ct o-hide" ref={tableRef}>
      {state.height && (
        <div className="col f-rest o-hide">
          <Table
            className="doc-table"
            sticky
            locale={{
              emptyText:
                rows === null ? (
                  <div
                    className="col w-100"
                    style={{ height: getScrollOffset() - 30 }}
                  >
                    <OvalLoading isSmall />
                  </div>
                ) : !Boolean(state.searchedColumn) &&
                  Boolean(EmptyComponent) ? (
                  <EmptyComponent />
                ) : (
                  <EmptyData
                    message={
                      Boolean(state.searchedColumn)
                        ? "No Matching Data"
                        : props.emptyMessage
                    }
                    height={
                      emptyProps && emptyProps.height
                        ? emptyProps.height
                        : state.height - 140
                    }
                  />
                ),
            }}
            rowSelection={
              noSelection
                ? undefined
                : {
                    type: "checkbox",
                    onChange: (selectedRowKeys, selectedRows) => {
                      onSelectionChange && onSelectionChange(selectedRows);
                    },
                  }
            }
            scroll={{
              y: getScrollOffset(),
            }}
            pagination={false}
            columns={getColumns()}
            dataSource={rows || []}
          />
        </div>
      )}
      {totalCount > 0 && (
        <div className="col v-ctr h-ctr page-change w-100">
          <Pagination
            showSizeChanger={false}
            total={getPaginationCount()}
            onChange={handlePageChange}
            itemRender={customItemRender}
            pageSize={paginationConfig.pageSize}
            defaultCurrent={paginationConfig.defaultPage}
            current={currentPage === "recent" ? 1 : Number(currentPage) + 1}
          />
        </div>
      )}
    </div>
  );
};
